var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('monthlyIncentiveBonus.employeeId'))+" ")]),_c('Select',{staticClass:"from-label",class:{
                        'is-invalid': _vm.errors.has('employee_id')
                    },attrs:{"filterable":"","remote":"","remote-method":_vm.searchEmployee,"loading":_vm.loading},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfile),function(emp,index){return _c('Option',{key:index,attrs:{"value":emp.employee_id}},[_vm._v(" "+_vm._s(emp.card_id + '-' + (emp.employee_name_kh) + '-' + (emp.employee_name_en))+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('employee_id'))+" ")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('monthlyIncentiveBonus.incBonusId'))+" ")]),_c('Select',{staticClass:"from-label",class:{
                            'is-invalid': _vm.errors.has('inc_bonus_id')
                        },attrs:{"filterable":"","remote":"","remote-method":_vm.searchBonus,"loading":_vm.loading},model:{value:(_vm.model.inc_bonus_id),callback:function ($$v) {_vm.$set(_vm.model, "inc_bonus_id", $$v)},expression:"model.inc_bonus_id"}},_vm._l((_vm.incentiveBonus),function(bn,index){return _c('Option',{key:index,attrs:{"value":bn.inc_bonus_id}},[_vm._v(" "+_vm._s(bn.inc_bonus_item)+" ")])}),1),(_vm.errors.has('inc_bonus_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('inc_bonus_id'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('monthlyIncentiveBonus.incBonus'))+" ")]),_c('Input',{class:{
                        'is-invalid': _vm.errors.has('inc_bonus')
                    },attrs:{"type":"number"},model:{value:(_vm.model.inc_bonus),callback:function ($$v) {_vm.$set(_vm.model, "inc_bonus", _vm._n($$v))},expression:"model.inc_bonus"}}),(_vm.errors.has('inc_bonus'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('inc_bonus'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyAllowance.cycleYear')))]),_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"233px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select Year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('monthlyAllowance.cycleMonth')))]),_c('div',[_c('date-Picker',{class:{
                                'is-invalid': _vm.errors.has('cycle_month')
                            },staticStyle:{"width":"233px"},attrs:{"value":_vm.model.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-label form-label tw-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('itemOvertime.description'))+" ")]),_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-control",domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "description", $event.target.value)}}})])])])]),_c('div',[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew'))+" ")]):_vm._e(),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }